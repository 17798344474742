import React, { useState, useRef } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

import styled from "styled-components";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";
import colors from "../helpers/styles/colors";

const PanelExpand = styled.div`
  overflow: hidden;
  max-height: ${({ isopen }) => (isopen === "true" ? "800px" : "0px")};
  transition: max-height 0.3s cubic-bezier(0.4, 0, 1, 1);

  .item__body {
    padding: 0 ${spacing.gutter25};
    text-align: left;
  }
`;

const ExpandableItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  .item__heading {
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    outline: none;
  }

  .item__title {
    width: 100%;
    padding: 1rem 0;

    font-size: 18px;
    text-decoration: underline;
    color: ${colors.dustyBlue};
    margin: 0;

    @media ${breakpoints.tablet} {
      font-size: 20px;
    }
  }
`;

const SmellsLikeALink = styled.button`
  padding: unset;
  border: unset;
  font-family: unset;
  background: unset;
  color: ${colors.dustyBlue};
  cursor: pointer;
  display: inline;
  font-size: unset;
  font-weight: unset;
`;

const ExpandableItem = ({ data, props }) => {
  const { frontmatter } = data.markdownRemark;

  const [isOpen, setIsOpen] = useState("");
  const content = useRef(null);

  const sectionId = `section-read-more`;

  const toggleDrawer = () => {
    setIsOpen(isOpen === "" ? "expanded" : "");
  };

  return (
    <ExpandableItemContainer className={`${isOpen ? " expanded" : ""}`}>
      <div
        className={`item__heading${isOpen ? " expanded" : ""}`}
        onClick={toggleDrawer}
        onKeyDown={(e) => {
          switch (e.key) {
            case " ":
            case "Enter":
              toggleDrawer();
              break;
            default:
          }
        }}
        role="button"
        id={sectionId}
        aria-controls={sectionId}
        aria-expanded={isOpen ? "true" : "false"}
        tabIndex={0}
      >
        <h4 className="item__title">
          <SmellsLikeALink
            data-toggle="collapse"
            tabIndex={0}
            title={frontmatter.mainpitchFooterReadMore.title}
            aria-label={frontmatter.mainpitchFooterReadMore.title}
          >
            {frontmatter.mainpitchFooterReadMore.title}
          </SmellsLikeALink>
        </h4>
      </div>
      <PanelExpand
        ref={content}
        role="region"
        aria-labelledby={sectionId}
        className={`${isOpen ? " collapsed" : ""}`}
        isopen={isOpen ? "true" : "false"}
      >
        <div className="item__body">
          <ReactMarkdown
            children={frontmatter.mainpitchFooterReadMore.description}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
          />
        </div>
      </PanelExpand>
    </ExpandableItemContainer>
  );
};

ExpandableItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

const ExpandableItemQuery = (props) => (
  <StaticQuery
    query={graphql`
      query ExpandableItemQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            mainpitchFooterReadMore {
              title
              description
            }
          }
        }
      }
    `}
    render={(data) => <ExpandableItem data={data} {...props} />}
  />
);

export default ExpandableItemQuery;
