/* eslint-disable react/jsx-no-target-blank */

import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import colors from "../helpers/styles/colors";
import typography from "../helpers/styles/typography";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";

import Facebook from "../images/social/facebook.svg";
import Twitter from "../images/social/twitter.svg";

import ExpandableItem from "../components/ExpandableItem";
import { LinkStyle } from "../helpers/styles/LinkStyle";

const FooterContainer = styled.footer`
  position: relative;
  padding: ${spacing.gutter40};
  background-color: ${colors.smokyWhite};
`;
const FooterContainerWrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;

  ul {
    padding: 0;
    li {
      list-style-type: none;
    }
  }
`;

const FooterMenu = styled.div`
  max-width: 950px;
  margin: 0 auto;
`;

const FooterMenuList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  .footer-menu-list-item {
    flex-grow: 1;
    flex-basis: 50%;
    margin-bottom: ${spacing.gutter30};
    text-align: left;

    @media ${breakpoints.tablet} {
      flex-basis: 24%;
    }

    a {
      display: block;
      margin-bottom: 5px;
      font: ${typography.subtitle};
      ${LinkStyle()}
    }

    .subtitle {
      font-size: ${typography.fontSizeHS};
      margin-bottom: 0;
    }
  }
`;

const FooterQuickLinks = styled.div`
  .footer-menu-list-item--horizontal {
    margin-bottom: ${spacing.gutter20};

    li {
      display: inline-flex;
      &:last-child a:after {
        content: "";
      }
    }

    a {
      position: relative;
      font-size: ${typography.fontSizeRegular};
      font-weight: bold;
      &:after {
        content: "|";
        padding: 0 7px 0 5px;
      }
      ${LinkStyle()}
    }
  }
`;

const FooterDisclaimer = styled.div`
  max-width: 950px;
  margin: 0 auto;
  padding-bottom: ${spacing.gutter40};
  border-bottom: 3px solid #d5d5d5;
  p {
    font-size: ${typography.fontSizeRegular};
  }
`;

const Footer = () => {
  return (
    <FooterContainer role="contentinfo">
      <FooterContainerWrapper>
        <FooterMenu>
          <FooterMenuList>
            <div className="footer-menu-list-item">
              <h3 className="subtitle-small">Help</h3>
              <ul>
                <li>
                  <Link to="/help" title="Help & Support" aria-label="Help & Support" tabIndex="0">
                    Help & Support
                  </Link>
                </li>
                <li>
                  <Link to="/feedback" title="Complaints" aria-label="Complaints" tabIndex="0">
                    Complaints
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-menu-list-item">
              <h3 className="subtitle-small">About us</h3>
              <ul>
                <li>
                  <Link to="/about">Who are we?</Link>
                </li>
              </ul>
            </div>

            <div className="footer-menu-list-item">
              <h3 className="subtitle-small">Connect with us</h3>
              <a
                title="facebook"
                href="https://www.facebook.com/boostpoweruk"
                target="_blank"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "10px",
                }}
                data-testid="facebook-icon-boost"
              >
                <Facebook style={{ width: "33px", height: "33px" }} />
              </a>
              <a
                title="twitter"
                href="https://twitter.com/boostpoweruk"
                target="_blank"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "10px",
                }}
                data-testid="twitter-icon-boost"
              >
                <Twitter style={{ width: "33px", height: "33px" }} />
              </a>
            </div>
          </FooterMenuList>
        </FooterMenu>

        <FooterQuickLinks>
          <div className="footer-menu-list-item--horizontal">
            <ul>
              <li>
                <Link
                  to="/privacy-policy"
                  title="Privacy policy & Cookies"
                  aria-label="Privacy policy & Cookies"
                  tabIndex="0"
                >
                  Privacy policy & Cookies
                </Link>
              </li>
              <li>
                <Link
                  to="/terms/modern-slavery"
                  title="Modern Slavery Statement"
                  aria-label="Modern Slavery Statement"
                  tabIndex="0"
                >
                  Modern Slavery Statement
                </Link>
              </li>
              <li>
                <Link
                  to="/health-and-safety"
                  title="Health & Safety Statement"
                  aria-label="Health & Safety Statement"
                  tabIndex="0"
                >
                  Health & Safety Statement
                </Link>
              </li>
              <li>
                <Link to="/help" title="Contact us" aria-label="Contact us" tabIndex="0">
                  Contact us
                </Link>
              </li>
              <li>
                <Link
                  to="/terms/what-to-do-in-an-emergency"
                  title="Emergency"
                  aria-label="Emergency"
                  tabIndex="0"
                >
                  Emergency
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  title="Core Terms & Conditions"
                  aria-label="Core Terms & Conditions"
                  tabIndex="0"
                >
                  Core Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
        </FooterQuickLinks>

        <FooterDisclaimer>
          <p>
            OVO Energy Ltd, trading as Boost, 1 Rivergate, Temple Quay, Bristol BS1 6ED, Registered
            in England and Wales No. 06890795, VAT No. 100119879. OVO Energy is a FIT licensee
          </p>
          <ExpandableItem />
        </FooterDisclaimer>
      </FooterContainerWrapper>
    </FooterContainer>
  );
};

export default Footer;
